import { IEnvironment } from './IEnvironment';

export const environment: IEnvironment = {
  production: false,
  msalConfig: {
    auth: {
      clientId: 'c9074463-679b-4570-aeab-c83567472130',
      authority: 'https://hvsidpuat.ciamlogin.com/',
      redirectUri: window.location.origin,
      postLogoutRedirectUri: '/',
      loginFailedRoute: '/login-failed',
    },
    apiConfig: {
      scopes: ['api://34d74b23-5e0e-4057-b08b-c12d8ca309a3/*'],
    },
    sepAdminIdTokenClaim: '7bba82fc-6803-4d6c-a1fc-b6c4f0fbf376',
  },
  backendConfig: {
    baseUrl: 'https://as-securityportal-development.azurewebsites.net/api/v1',
  },
  general: {
    baseUrl: 'https://my-dev.is-fox.com',
    hvsPrivacyPolicyUrl: 'https://get.is-fox.com/legal/datenschutz',
    hvsLicenseTermsUrl: 'https://get.is-fox.com/legal/lizenzbestimmungen',
    hvsWebflowUrl: 'https://is-fox.webflow.io/plans?employeeNumber=15',
    defaultLang: 'de',
    internationalFallbackLanguage: 'en',
    hvsSupportContactEmail: 'support@is-fox.de',
  },
  analytics: {
    mixpanelProjectToken: '',
    enableAnalytics: false,
  },
  stripe: {
    publicKey:
      'pk_test_51P3KBxRqCu6CCU9LQhAPwiY2JU1UgvjrcsrPNRwm7HXGzj9YDqZA0W0lTz5lxT1I6qjOu7z0RXcMBFEbCyTrySXC00qMyoNWlz',
  },
  productFruits: {
    workspaceId: '71Ds8XGQfFzjug4F',
  },
};
